// Trick VH for mobile Devices
let vh = window.innerHeight * 0.01;

let setVHSize = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

document.addEventListener('DOMContentLoaded', setVHSize);
window.addEventListener('resize', setVHSize);


// Servicenav
document.addEventListener('DOMContentLoaded', () => {
  document.querySelector('.headerbar__servicenavtoggler').addEventListener('click', () => {
    document.body.classList.toggle('body--activeservicenav');
    document.documentElement.classList.toggle('body--activeservicenav');
  });
})

// Navigation
document.addEventListener('DOMContentLoaded', () => {
  document.querySelector('.headerbar__navtoggler').addEventListener('click', () => {
    if (window.innerWidth < 992) {
      document.body.classList.toggle('body--activenav');
      document.documentElement.classList.toggle('body--activenav');
    }
  });
})

// Offcanvas Behaviour

for (let accordionItem of document.querySelectorAll('.accordion__item')) {
  for (let itemToggle of accordionItem.querySelectorAll('[data-toggle="true"]')) {
    itemToggle.addEventListener('click', () => {
      accordionItem.classList.toggle('opened')
    })
  }
}

let setMenuLevel = (level) => {
  let menu = document.querySelector('.headerbar__navigation > ul');

  for (let clickedItem of document.querySelectorAll('.headerbar__navigation li.parent.clicked')) {
    clickedItem.classList.remove('clicked')
  }

  menu.setAttribute('class', menu.getAttribute('class').replace(/(nav--level\d)/, ''))
  menu.classList.add('nav--level' + level)

}

// Main menu behavior
for (let menuItem of document.querySelectorAll('.headerbar__navigation li.parent > a, .headerbar__navigation li.parent > span')) {
  menuItem.addEventListener('click', (e) => {
    if (window.innerWidth < 992) {
      const item = e.target;

      // Prevent link opening
      e.preventDefault()

      const parentClass = item.parentElement.parentElement.getAttribute('class')
      const parentLevel = parentClass.match(/list--level(\d)/)[1]

      setMenuLevel(parseInt(parentLevel) + 1)

      item.parentElement.classList.add('clicked')

      if (item.parentElement.parentElement.parentElement.classList.contains('parent')) {
        item.parentElement.parentElement.parentElement.classList.add('clicked')
      }
    }
  });
}

for (let menuItem of document.querySelectorAll('.headerbar__navigation button.mainnav__backlink')) {
  menuItem.addEventListener('click', (e) => {
    if (window.innerWidth < 992) {
      e.preventDefault()

      const parentClass = e.target.parentElement.parentElement.getAttribute('class')
      const parentLevel = parentClass.match(/list--level(\d)/)[1]

      setMenuLevel(parseInt(parentLevel) - 1)

      e.target.parentElement.parentElement.parentElement.classList.add('clicked')

      if (e.target.parentElement.parentElement.parentElement.parentElement.parentElement.classList.contains('parent')) {
        e.target.parentElement.parentElement.parentElement.parentElement.parentElement.classList.add('clicked')
      }
    }
  });
}


// Accordion

for (let accordionitem of document.querySelectorAll('.accordion__item')) {
  accordionitem.querySelector('.accordion__item-title').addEventListener('click', () => {
    accordionitem.classList.toggle('accordion__item--open')
  })
}

// Footerheadings
document.addEventListener('DOMContentLoaded', () => {
  for (let footerHeading of document.querySelectorAll('.footerheading')) {
    footerHeading.addEventListener('click', () => {
      footerHeading.classList.toggle('opened');
    })
  }
})